import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../../app/store';
import { ScriptFormat, ScriptParagraph, WorkspaceRepositoriesState } from './types';
import api from '../../api';

import storage from 'store';
import { toastr } from 'react-redux-toastr';
import { AxiosResponse } from 'axios';
import { Error } from '../../api/apiClient';
import _ from 'lodash';
import { removeSpacesBeginningParagraphs } from './utility';
import * as Sentry from '@sentry/react';
import moment from 'moment';
import { File, Task } from '../../models/taskTypes';
import { ErrorCode } from '../../models';

const initialState: WorkspaceRepositoriesState = {
  isFetching: false,
  isValid: false,
  error: null,
};

export const workspaceSlice = createSlice({
  name: 'WORKSPACE',
  initialState,
  reducers: {
    startTaskFetch(state) {
      state.isFetching = true;
      state.isValid = false;
      state.error = null;
    },
    successTaskFetch(
      state,
      { payload: { task } }: PayloadAction<{ task: Task & { playFile: File } }>,
    ) {
      state.isFetching = false;
      state.isValid = true;
      state.error = null;
      state.task = task;
      state.playFile = task.playFile;
      state.order = task.playFile.order;

      // 임시 저장된 스크립트(로컬스토리지에 저장된 스크립트) 제거
      removeScriptInfoFromLocalStorage(task.uid);
    },
    failTaskFetch(state, { payload }: PayloadAction<any>) {
      state.isFetching = false;
      state.isValid = false;
      state.error = payload;
    },
    closePopupFailurePullTask(state) {
      state.error = null;
    },
  },
});

export default workspaceSlice.reducer;

export const { startTaskFetch, successTaskFetch, failTaskFetch, closePopupFailurePullTask } =
  workspaceSlice.actions;

export const createScript = {
  object: 'block',
  type: 'paragraph',
  data: {
    start: 0,
    end: 0,
  },
  children: [
    {
      text: '',
      data: {
        dataStart: 0,
        dataEnd: 0,
        confidence: 1,
      },
    },
  ],
};

/**
 * 로컬스토리지에 저장된 task 가 있는지 확인.
 * @currentTaskUid: 현재 에디터에서 불러오는 task.
 */
const isTaskInLocalStorage = (currentTaskUid: string) => {
  const localStorageAll = Object.keys(localStorage);

  localStorageAll.forEach((item) => {
    // tasks 가 아니면 아래 코드는 필요 없으니 나가자.
    if (item.indexOf('tasks.') < 0) {
      return;
    }

    /*
     코드 수정전 저장된 내역 삭제를 위해(이후에 지울 부분)
    - '비밀번호'저장을 sessionStorage, cookie 에 저장 방법으로 바꾸면서, 로컬스토리지에 저장된 패스워드 제거 (2022.03.28)*/
    const itemSplit = item.split('.');
    const itemId = itemSplit[1];
    const itemKey = itemSplit[2];
    if (itemKey === 'password') {
      storage.remove(item);
      storage.remove(`tasks.${itemId}.workerInfo`);
      storage.remove(`tasks.${itemId}.dateScriptChanged`);
      return;
    }

    const itemScript = storage.get(`tasks.${itemId}.script`);
    const itemIsScriptChanged = storage.get(`tasks.${itemId}.isScriptChanged`);

    // 임시 저장이 있다면 넘어감.
    if (itemScript && itemIsScriptChanged) {
      /* 현재 task 의 임시저장된 내용이 아니면서, 임시 저장 날짜가 오래되었다면 지워야 하지 않을까? (2022.03.29) */
      const scriptChangeDate = storage.get(`tasks.${itemId}.scriptChangeDate`);
      if (currentTaskUid !== itemId && scriptChangeDate) {
        const currentDate = new Date();
        const after7Days = new Date(scriptChangeDate);
        after7Days.setDate(after7Days.getDate() + 7);
        // 현재 날짜가 '임시저장' 된 날짜에서 7일 이후라면 로컬스트로지에 저장된 임시저장내용 제거.
        if (currentDate.toLocaleString() > after7Days.toLocaleString()) {
          removeScriptInfoFromLocalStorage(itemId);
        }
      }

      return;
    }

    // script 또는 isScriptChanged 둘 중 하나라도 없는 경우에는 지워져야 했던 내용이 제대로 지워지지 못한것이므로 제거.
    removeScriptInfoFromLocalStorage(itemId);
  });
};

/**
 * 임시 저장(로컬스토리에 저장된) 스크립트가 있는 경우
 * @param paramsUid
 * @param paramsTask - 호출한 task 정보
 * @param paramsStorageScript - 로컬스토리지에 저장되어 있던 스크립트
 */
const confirmUseTemporaryScrips =
  (
    paramsUid: Task['uid'],
    paramsTask: Task & { playFile: File },
    paramsStorageScript: ScriptParagraph[],
  ): AppThunk =>
  (dispatch) => {
    // TODO - 기획서에 없는 것.
    const taskUid = paramsUid;
    const task = paramsTask;
    const storageScript = paramsStorageScript;
    const temporarilySavedDate = storage.get(`tasks.${taskUid}.scriptChangeDate`);

    // 변경된 스크립트 저장값이 true 면 저장된 스크립트가 같다는 것. (false 면 편집된 스크립트가 저장되지 못하고 작업창이 닫힌 것.)
    const toastrConfirmOptions = {
      onOk: () => {
        task.script.value = removeSpacesBeginningParagraphs(storageScript);
        dispatch(pushStorageScript(taskUid, task, undefined, { callLocation: 'storageScript' }));
      },
      onCancel: () => {
        // 임시 저장된 스크립트(로컬스토리지에 저장된 스크립트) 사용 안함.
        dispatch(successTaskFetch({ task }));
      },
      okText: '불러오기',
      cancelText: '취소',
    };
    const msg = `저장되지 못한 이전 작업 내용이 있습니다.\n이전 작업 내용을 불러오겠습니까?
  ${temporarilySavedDate ? '\n[이전 작업 일시: ' + temporarilySavedDate + ']' : ''}`;

    toastr.confirm(msg, toastrConfirmOptions);
  };

/* '화자 정보'는 변경했는데, 문단의 화자 변경 업데이트가 제대로 이뤄지지 않은 경우. */
const changeScriptSpeakerToChangedSpeaker =
  ({
    taskUid,
    task,
    isUpdatedSpeakerName,
  }: {
    taskUid: Task['uid'];
    task: Task & { playFile: File };
    isUpdatedSpeakerName: { before: string; after: string };
  }): AppThunk =>
  (dispatch) => {
    const beforeName = _.get(isUpdatedSpeakerName, 'before');
    const afterName = _.get(isUpdatedSpeakerName, 'after');
    const taskScript = task.script.value as ScriptParagraph[];
    const sameSpeakerInParagraph = _.filter(taskScript, (o) => o.data.speaker === beforeName);
    // console.log('sameSpeakerInParagraph: ', sameSpeakerInParagraph);
    storage.remove(`tasks.${taskUid}.updateSpeakerName`);

    /* '화자 정보'에서 삭제, 변경된 이름이 문단별 '화자'로 지정되어 있는 경우에만 스크립트 변경 */
    if (sameSpeakerInParagraph.length > 0) {
      // 변경된 '화자 정보' 에 맞게 에디터의 문단별 화자를 변경한 값.
      task.script.value = _.map(taskScript, (item) => {
        if (item.data.speaker === beforeName) {
          return {
            ...item,
            data: {
              ...item.data,
              speaker: afterName,
            },
          };
        }
        return { ...item };
      });

      dispatch(
        pushStorageScript(taskUid, task, undefined, { callLocation: 'updateSpeakerWithStorage' }),
      );
    } else {
      /*'화자 정보'에서 삭제, 변경된 이름이 문단별 '화자'로 지정되어 있지 않은 경우*/
      dispatch(successTaskFetch({ task }));
    }
  };

/* 문제가 발생했을때, 해당 작업을 검색하기 위해서...작업자의 이름등   */
const setWorkerInfo = (taskUid: Task['uid'], task: Task & { playFile: File }) => {
  sessionStorage.setItem(
    `tasks.${taskUid}.workerInfo`,
    JSON.stringify({
      taskId: task.id,
    }),
  );
};

export const pullTask =
  (taskUid: Task['uid'], type?: 'video'): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(startTaskFetch());
      const task = await api.getTask(taskUid, type);
      setWorkerInfo(taskUid, task);

      isTaskInLocalStorage(taskUid);

      switch (task.scriptFormat) {
        case ScriptFormat.TXT /* 에디터 편집 영역이 textarea 태그를 사용했던 이전 작업건 */:
          storage.remove(`tasks.${taskUid}.updateSpeakerName`);
          dispatch(successTaskFetch({ task }));
          break;

        case ScriptFormat.TPX:
        default:
          const storageScript = storage.get(`tasks.${taskUid}.script`);
          const taskEditedAt = task.editedAt && moment(task.editedAt).format('YYYY-MM-DD HH:mm:ss');
          const temporarilySavedDate = storage.get(`tasks.${taskUid}.scriptChangeDate`);
          const isUpdatedSpeakerName = storage.get(`tasks.${taskUid}.updateSpeakerName`);
          const isScriptChanged = storage.get(`tasks.${taskUid}.isScriptChanged`);
          // 스토리지에 저장된 임시스크립트와 DB의 스크립트가 같은지 확인(task의 script 변경이 api 저장까지 됐으나 결과를 받지 못한 경우가 있을 수 있다.)
          let isDbAndStorageEqual = false;

          /* stt 가 제대로 이뤄지지 않아 value 가 없는 상황 */
          if (!_.get(task.script, 'value')) {
            toastr.error(
              '오류',
              '스크립트 생성에 문제가 발생하여 접근 할 수 없습니다. 관리자에게 문의해주세요.',
              {
                timeOut: 0,
              },
            );

            return;
          }

          if (task.script.value.length === 0) {
            // script 가 빈 값을 가지고 있는 경우. 빈 문단 추가.
            task.script.value = [createScript];
          } else {
            // 로컬에 저장된 스크립트와 DB 에서 가져온 스크립트 비교는 공백을 제거하기 전에 진행.
            isDbAndStorageEqual = storageScript && _.isEqual(task.script.value, storageScript);
            // 문단별 첫번째 단어의 앞에 있는 공백 제거후
            task.script.value = removeSpacesBeginningParagraphs(task.script.value);
          }

          /* 로컬에 저장된 임시 스크립트가 있으면서, 로컬에 저장된 임시 스크립트 저장 날짜보다 받아온 task.editedAt 날짜가 이후인 경우.(임시 스크립트가 저장된 컴퓨터가 아닌 다른 컴퓨터에서 작업한 경우) 받아온 task 의 스크립트 사용. */
          if (
            taskEditedAt &&
            temporarilySavedDate &&
            moment(taskEditedAt).isAfter(temporarilySavedDate)
          ) {
            const savedWorkerInfo =
              sessionStorage.getItem(`tasks.${taskUid}.workerInfo`) || "{taskId: ''}";
            const workerInfo = JSON.parse(savedWorkerInfo);
            storage.remove(`tasks.${taskUid}.updateSpeakerName`);
            dispatch(successTaskFetch({ task }));

            Sentry.captureMessage(
              '[가설: 작업 컴퓨터가 다르다] 로컬에 저장된 임시 스크립트 저장 날짜보다 받아온 task.editedAt 날짜가 이후인 경우:' +
                JSON.stringify({
                  '편집 일시': taskEditedAt,
                  '임시저장된 일시': temporarilySavedDate,
                  '작업 id': workerInfo.taskId,
                }),
            );

            /* '화자 정보'는 변경했는데, 문단의 화자 변경 업데이트가 제대로 이뤄지지 않은 경우. */
          } else if (isUpdatedSpeakerName) {
            dispatch(changeScriptSpeakerToChangedSpeaker({ taskUid, task, isUpdatedSpeakerName }));

            /* 로컬스토리지에서 가져온 스크립트 변경 상태가 true 라면, 임시 저장된 스크립트 사용 여부를 사용자에게 확인하는 단계로 넘어간다. */
          } else if (storageScript && isScriptChanged && !isDbAndStorageEqual) {
            dispatch(confirmUseTemporaryScrips(taskUid, task, storageScript));
          } else {
            dispatch(successTaskFetch({ task }));
          }
          break;
      }
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.error('pullTask: ', err);

      const errorData = (err as AxiosResponse<Error>).data || {};
      let message = _.get(errorData, 'message');
      const errorCode = _.get(errorData, 'code');

      dispatch(failTaskFetch(errorData));

      switch (errorCode) {
        case ErrorCode.TASK_NOT_FOUND: {
          message = '해당 작업건이 존재하지 않습니다.\n id를 다시한번 확인하세요.';
          break;
        }
        case ErrorCode.INVALID_TASK_PASSWORD:
        case ErrorCode.VALIDATION: {
          message = '비밀번호가 틀렸습니다.';
          break;
        }
        default: {
          if (!errorCode) {
            return;
          }
          message =
            '오류가 발생하였습니다.\n 관리자에게 문의해주세요.' +
            (message ? '\n[' + message + ']' : '') +
            (errorCode ? '\n(code: ' + errorCode + ')' : '');
        }
      }

      if (errorCode !== ErrorCode.NO_PERMISSION) {
        toastr.error('오류', message);
      }
    }
  };

/*** 에디터 첫 접근시 스크립트 내용을 로컬에 저장한 임시 스크립트를 사용하는 경우: 작업건의 스크립트를 수정 ***/
export const pushStorageScript =
  (
    taskUid: Task['uid'],
    task: Task & { playFile: File },
    format?: ScriptFormat,
    optional: { callLocation?: string } = {},
  ): AppThunk =>
  async (dispatch) => {
    // TODO - 기획서에 없는 것.
    Sentry.captureMessage(
      '[에디터 로드] 스크립트를 ( ' +
        (optional.callLocation
          ? optional.callLocation === 'storageScript'
            ? '임시 저장'
            : '문단별 화자 변경'
          : '') +
        ' ) 변경',
    );

    try {
      await api.updateTaskScript(
        taskUid,
        task.script.value,
        format || ScriptFormat.TPX,
        optional.callLocation,
      );
      dispatch(successTaskFetch({ task }));
    } catch (err) {
      dispatch(failTaskFetch(err));
      toastr.error('오류', '임시 저장된 스크립트로 내용을 저장하면서 오류가 발생하였습니다.', {
        timeOut: 5000,
      });

      const taskEditedAt = task.editedAt && moment(task.editedAt).format('YYYY-MM-DD HH:mm:ss');
      const savedWorkerInfo =
        sessionStorage.getItem(`tasks.${taskUid}.workerInfo`) || "{taskId: ''}";
      const workerInfo = JSON.parse(savedWorkerInfo);
      const temporarilySavedDate = storage.get(`tasks.${taskUid}.scriptChangeDate`);

      Sentry.captureException(err);

      Sentry.captureMessage(
        '[오류][storage script] 임시 저장된 스크립트로 내용을 저장하면서 오류 발생:' +
          JSON.stringify({
            '편집 일시': taskEditedAt,
            '임시저장된 일시': temporarilySavedDate,
            '작업 id': workerInfo.taskId,
          }),
      );
    }
  };

// 임시 저장된 스크립트(로컬스토리지에 저장된 스크립트) 제거
export const removeScriptInfoFromLocalStorage = (taskUid: Task['uid']) => {
  storage.remove(`tasks.${taskUid}.isScriptChanged`);
  storage.remove(`tasks.${taskUid}.script`);
  storage.remove(`tasks.${taskUid}.scriptChangeDate`);
};
