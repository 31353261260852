import {
  ScriptFormat,
  ScriptParagraph,
  SpellCheckData,
  TaskScriptHistory,
} from '../features/workspace/types';
import apiClient, { Data } from './apiClient';
import { File, SpeakerInfo, Task } from '../models/taskTypes';

/**
 * 해당 작업건 정보를 조회한다.
 * @param taskId
 * @param type
 */
const getTask = async (taskId: Task['uid'], type?: string) => {
  const params: { type?: string } = {};
  if (type) {
    params.type = type;
  }
  const { data } = await apiClient.get<Data<Task & { playFile: File }>>(
    `/tasks/${taskId}`,
    params,
  );
  return data;
};

/**
 * 해당 작업 상태를 수정한다.
 * @param taskId
 */
const updateTaskStatus = async (taskId: Task['uid']) => {
  const { data } = await apiClient.put<Data<Task & { playFile: File }>>(
    `/tasks/${taskId}/status`,
    { status: '타이핑 완료' },
  );
  return data;
};

/**
 * 작업건의 스크립트를 수정한다.
 * @param taskUid
 * @param value
 * @param format
 * @param callLocation 임시저장 내용을 사용하는 경우등 예외로 호출되는 위치 확인을 위해서
 */
const updateTaskScript = async (
  taskUid: Task['uid'],
  value: ScriptParagraph[] | string,
  format: ScriptFormat,
  callLocation?: string,
) => {
  const { data } = await apiClient.put<Data<Task>>(`/tasks/${taskUid}/script`, {
    value,
    format,
    callLocation,
  });
  return data;
};

/**
 * 작업건의 화자정보를 수정한다.
 * @param taskUid
 * @param value
 */
const updateTaskSpeakers = async (taskUid: Task['uid'], value: SpeakerInfo[]) => {
  const { data } = await apiClient.put<Data<Task>>(`/tasks/${taskUid}/details/speakers`, {
    value,
  });
  return data;
};

/**
 * 히스토리 조회 GET /tasks/{task_id}/task-script-histories?last_id=xxxxx&per_page=10
 * @param taskUid
 * @param perPage
 * @param lastId
 */
const getTaskScriptHistories = async (
  taskUid: Task['uid'],
  perPage: number,
  lastId?: number,
) => {
  const params: {
    perPage: number;
    lastIndex?: number;
  } = { perPage, lastIndex: lastId };

  const { data, meta } = await apiClient.get<Data<TaskScriptHistory[]>>(
    `/tasks/${taskUid}/task-script-histories`,
    {
      ...params,
      sortDirection: 'DESC',
    },
  );
  return { data, meta };
};

/**
 * 히스토리 열람 GET /tasks/{task_id}/task-script-histories/{id}
 * @param taskUid
 * @param scriptHistoryId
 */
const getTaskScriptHistory = async (taskUid: Task['uid'], scriptHistoryId: number) => {
  const { data } = await apiClient.get<Data<TaskScriptHistory>>(
    `/tasks/${taskUid}/task-script-histories/${scriptHistoryId}`,
  );
  return data;
};

/**
 * 맞춤법 검사 요청
 * @param script
 */
const spellCheck = async (script: string) => {
  const params = {
    query: script,
    help: 'on',
    disable_new: 'on',
    disable_purified: 'on',
  };

  const data = await apiClient.post<SpellCheckData>(`/nlp/spell-check`, params);
  return data.data || data as SpellCheckData; // TODO: 종민님 오류 고치면 수정
};

export {
  getTask,
  updateTaskStatus,
  updateTaskScript,
  updateTaskSpeakers,
  getTaskScriptHistories,
  getTaskScriptHistory,
  spellCheck,
};
