import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import storage from 'store';

import { ScriptParagraph, ScriptWord } from './types';
import { BaseEditor, createEditor, Editor, Element, Node, Point, Range, Transforms } from 'slate';
import {
  Editable,
  ReactEditor,
  RenderElementProps,
  RenderLeafProps,
  Slate,
  useSlateStatic,
  withReact,
  withWindow,
} from 'slate-react';
import { changeIsEventsFromOutside, commitScript, pushTaskScript } from './editorBoxSlice';
import { RootState } from '../../app/store';
import { timeStamp, toNum } from '../../utils/format';
import { HistoryEditor, withHistory } from 'slate-history';
import _, { debounce, isEqual } from 'lodash';
import { MenuItem, withStyles } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import HoveringToolbar from './HoveringToolbar';
import classNames from 'classnames';
import moment from 'moment';
import './types';
import { changeSpeakerOfFirstParagraph } from './speakersSlice';
import { editedScript, find, finishSeeking, replace } from './findReplaceSlice';
import { SpeakerInfo } from '../../models/taskTypes';
import { config } from '../../config';
import {
  changeSpellChecked,
  changeSpellCheckingLimit,
  commit as commitChanges,
  finishCommitting,
} from './spellCheckSlice';

const EditorBox = (props: { refPlayer: React.RefObject<ReactPlayer>; readOnly?: boolean }) => {
  const editor = useMemo(
    () =>
      withWindow<BaseEditor & ReactEditor & HistoryEditor>(withReact(withHistory(createEditor())), {
        showMinimap: storage.get('windowing.showMinimap') || false,
        scrollDelay: 50,
        frameThreshold: 300,
        adjustLimit: 3,
      }),
    [],
  );
  const { refPlayer, readOnly } = props;

  const dispatch = useDispatch();
  const taskUid = useSelector((rootState: RootState) => rootState.workspace.repositories.task!.uid);
  const script = useSelector(
    (rootState: RootState) => rootState.workspace.editor.script,
  ) as ScriptParagraph[];
  const isEventsFromOutside = useSelector(
    (rootState: RootState) => rootState.workspace.editor.isEventsFromOutside,
  );
  const playedSecondsForScroll = useSelector(
    (rootState: RootState) => rootState.workspace.player.playedSecondsForScroll,
  );
  const speakerOfFirstParagraph = useSelector(
    (rootState: RootState) => rootState.workspace.editSpeakers.speakerOfFirstParagraph,
  );

  const isSpellChecked = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.isSpellChecked,
  );

  const isCheckingLength = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.isCheckingLength,
  );
  const isSpellCheckCommitting = useSelector(
    (rootState: RootState) => rootState.workspace.spellCheck.isCommitting,
  );

  const {
    isFinding,
    isReplacing,
    seekToIndex,
    results,
    isOpen: isFindReplaceOpen,
    isScriptEdited,
  } = useSelector((rootState: RootState) => rootState.workspace.findReplace);

  const { isCommitting } = useSelector((rootState: RootState) => rootState.workspace.spellCheck);

  /*** 외부 이벤트로 인해 script 변경시 slate 에디터 값 변경 위함 ***/
  useEffect(() => {
    if (isEventsFromOutside && editor.children !== script) {
      editor.children = script;
      editor.onChange();
      dispatch(changeIsEventsFromOutside(false));
    }
  }, [dispatch, editor, script, isEventsFromOutside]);

  /** 맞춤법 검사 */
  useEffect(() => {
    if (isCommitting) {
      dispatch(commitChanges(editor));
    }
  }, [isCommitting]);

  /** 찾기 바꾸기 */
  useEffect(() => {
    if (isFinding) {
      dispatch(find({ editor }));
    }
  }, [isFinding]);

  /** 찾기 바꾸기 */
  useEffect(() => {
    if (isReplacing) {
      dispatch(replace({ editor }));
    }
  }, [isReplacing]);

  /** 찾기 바꾸기 */
  useEffect(() => {
    if (seekToIndex !== undefined && results.length) {
      const result = results[seekToIndex];
      editor.window.scrollToItem(result.offset[0]);
      // @ts-ignore
      const paragraph: ScriptParagraph = editor.children[result.offset[0]];
      let index = 0;
      const u = result.offset[1]; /* - result.context.previous.length;*/
      paragraph.children.some((word) => {
        index += word.text.length;
        if (index >= u) {
          refPlayer.current!.seekTo(word.data.dataStart, 'seconds');
          return true;
        }
        return false;
      });
      dispatch(finishSeeking());
    }
  }, [seekToIndex, results, refPlayer, editor]);

  /*** seek: 클릭된 어절의 시간 값과 미디어 재생 싱크 ***/
  const handleChangeSeek = useCallback(
    (targetWord: ScriptWord) => {
      refPlayer.current!.seekTo(targetWord.data.dataStart, 'seconds');
    },
    [refPlayer],
  );

  /*** 이벤트: 스크립트 내용 click: 단축키(win: Ctrl, mac: command) + 클릭시 커서이동 ***/
  const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      // 짧은 시간 내에 발생한 연속 클릭 수가 2번을 초과(3번째에서 오류 발생: Uncaught TypeError: Cannot read properties of undefined (reading 'length')하면 무효화한다.
      if (event.detail > 2) {
        event.preventDefault();
      }
      if (event.ctrlKey || event.metaKey) {
        setTimeout(() => {
          const { selection } = editor;
          if (selection && Range.isCollapsed(selection)) {
            const [start] = Range.edges(selection);
            const [paragraphIndex, wordIndex] = start.path;
            const targetWord = (script[paragraphIndex] as Element).children[wordIndex];
            // 클릭된 어절의 시간 값과 미디어 재생 싱크 맞추기 위해서 클릭한 어절의 정보를 넘겨줌
            handleChangeSeek(targetWord as ScriptWord);
          }
        }, 0);
      }
    },
    [editor, script, handleChangeSeek],
  );

  const handleBeforeInput = useCallback(
    (event: Event) => {
      const { selection } = editor;
      // @ts-ignore
      switch (event.inputType) {
        case 'insertLineBreak':
        case 'insertParagraph': {
          /*
           * insertLineBreak: shift + enter
           * insertParagraph: enter
           * */
          event.preventDefault();

          // 두개의 블럭을 선택한 상태에서 문단 분리? 어떤 형태로? 우선  변화 없음으로 작업.
          if (selection!.focus.path[0] !== selection!.anchor.path[0]) {
            return;
          }

          // 위 블럭의 data.end 조정
          const block = editor.children[selection!.anchor.path[0]] as ScriptParagraph;
          const middle = block.children[selection!.anchor.path[1]].data.dataStart;
          Transforms.setNodes(
            editor,
            {
              data: {
                ...block.data,
                end: middle,
              },
            },
            { at: [selection!.anchor.path[0]] },
          );

          // 블럭 나누기
          Editor.insertBreak(editor);

          // 블럭을 나눈 다음 두번째 블럭 시작점에 커서가 위치하도록 조정
          if (editor.selection?.anchor.offset) {
            Transforms.move(editor, { unit: 'word', reverse: false });
          }

          // 추가한 블럭 정보
          const newSelection = editor.selection;
          const newBlockIndex = newSelection!.anchor.path[0];
          const newBlock = editor.children[newBlockIndex] as ScriptParagraph;
          const wordsLength = newBlock.children.length;
          const lastWordTimeEnd = newBlock.children[wordsLength - 1].data.dataEnd;

          /* 문단의 첫번째 단어의 앞에 있는 공백 제거
           * 첫번째 단어의 앞에 공백
           * 첫번째 단어가 모두 공백
           * 첫번째 단어가 모두 공백 + 그 다음 단어의 앞에 공백이 있다. <= 해결 안됨.
           *  */
          // 우선 에디터 처음 접근후 task api 했을때, 공백 없애주는 것만 적용해보자.
          /*
          const firstWord = newBlock.children[0];
          const firstWordText = firstWord.text || "";
          const spaceCount = firstWordText.search(/\S/);
          const nextWord = newBlock.children[1];

          if (spaceCount > 0) {
            /!* 첫번째 단어의 앞에 공백 *!/
            Transforms.delete(editor, {
              unit: "character",
              distance: spaceCount,
            });

          } else if (spaceCount === -1) {
            /!* 첫번째 단어가 모두 공백 *!/
            if (nextWord) {
              // 다음 단어가 있는지 확인후 머지
              Transforms.mergeNodes(editor, {
                at: [newBlockIndex, 1],
              });
              // Transforms.mergeNodes(editor, { at: [newBlockIndex, 1], hanging: true })
            }
          }
          */

          let newData = {
            start: middle,
            end: lastWordTimeEnd,
          };

          // @ts-ignore
          // '문단 블록'
          if (event.inputType === 'insertLineBreak') {
            newData = Object.assign(newData, {
              type: 'lineBreak',
            });
          }

          // 아래 블럭의 data.start 조정
          Transforms.setNodes(
            editor,
            {
              data: newData,
            },
            { at: [newSelection!.anchor.path[0]] },
          );
          break;
        }

        case 'deleteContentBackward': {
          if (!selection) {
            return;
          }

          let startBlockIndex = selection.anchor.path[0];
          let endBlockIndex = selection.focus.path[0];
          let endOffsetZero = selection.focus.offset === 0;
          let startOffsetZero = selection.anchor.offset === 0;

          // Point.compare: 포인트가 다른 포인트의 이전인지, 이후인지 나타내는 정수를 반환 (결과값 -1은 앞에서 뒤로 선택. 0과 1은 뒤에서 앞으로 선택.)
          if (Point.compare(selection.anchor, selection.focus) < 0) {
            startBlockIndex = selection.focus.path[0];
            endBlockIndex = selection.anchor.path[0];

            endOffsetZero = selection.anchor.offset === 0;
            startOffsetZero = selection.focus.offset === 0;
          }

          // 뒤에서 앞으로 선택한 경우를 기준으로 anchor 블럭.
          const startBlock = editor.children[startBlockIndex] as ScriptParagraph;

          // speaker 블럭과 lineBreak 블럭을 머지하게 되는 경우.
          if (endOffsetZero && startBlock.data.type === 'lineBreak') {
            // anchor, focus 가 같은 블럭이 아님 (두개의 블럭을 선택한 상태)
            if (startBlockIndex !== endBlockIndex) {
              // data.type 이 없으면, '화자 블록'
              const endBlock = editor.children[endBlockIndex] as ScriptParagraph;
              // endBlock 에 담긴 블럭이 lineBreak 블럭이라면 작업 없음.
              if (endBlock.data.type) {
                return;
              }

              // 시작 타임, 화자 정보는 'speaker 블럭' 의 데이터를 사용
              const startBlockData = {
                ...startBlock.data,
                speaker: endBlock.data.speaker,
              };
              // speaker 블럭에 들어가야 하는 데이터이므로 data.type 을 지운다.
              delete startBlockData.type;

              let transformBlockIndex = startBlockIndex;
              if (startOffsetZero) {
                transformBlockIndex = startBlockIndex - 1;

                /*
                * 문제는?
                * anchor(4번째 블럭), focus(1번째 블럭) 블럭이 다르면서
                * anchor 의 offset 이 0이고,
                * focus 의 offset 이 0인 상황에서 (예: {anchor: {path: [3, 0], offset: 0}, focus: {path: [0, 0], offset: 0}})
                * 백스페이지를 누렀을때, 하나의 블럭으로 머지 되지 않고,
                * 두 개의 블럭이 된다. (1~3번째 블럭이 하나의 블럭으로 머지, 4번째 블럭은 그대로 임)
                * slatejs 기본에서 발생하는 문제라서 수정 X.

                * Transforms.removeNodes(editor, {at: [transformBlockIndex]})
                * */
              }
              // 현재 블록의 정보 변경
              Transforms.setNodes(
                editor,
                {
                  data: startBlockData,
                },
                { at: [transformBlockIndex] },
              );

              // anchor, focus 가 같은 블럭. 드래그로 영역을 선택하지 않은 상태.(type 이 caret)
            } else if (endBlockIndex > 0 && Range.isCollapsed(selection)) {
              // data.type 이 없으면, '화자 블록'
              const endBlock = editor.children[endBlockIndex - 1] as ScriptParagraph;
              if (endBlock.data.type) {
                return;
              }

              // 시작 타임, 화자 정보는 '화자 블록' 의 데이터를 사용
              const startBlockData = {
                ...startBlock.data,
                speaker: endBlock.data.speaker,
              };
              // data.type 을 지운다.
              delete startBlockData.type;

              // 현재 블록의 정보 변경
              Transforms.setNodes(
                editor,
                {
                  data: startBlockData,
                },
                { at: [startBlockIndex] },
              );
            }
          }
        }
      }
    },
    [editor],
  );

  // 줄 바꿈. (alt + enter OR meta + enter)
  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    const { metaKey, altKey, key: eventKey } = event;
    if ((metaKey || altKey) && eventKey === 'Enter') {
      event.preventDefault();
      Transforms.insertText(editor, '\n');
    }
  }, [editor]);

  /*** 텍스트 붙여넣기 ***/
  const handlePaste = useCallback(
    (event: React.ClipboardEvent<HTMLDivElement>) => {
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        const text = event.clipboardData.getData('text/plain');
        if (text) {
          event.preventDefault();
          // 한 줄 이상의 빈줄은 공백으로 수정.
          editor.insertText(text.replace(/\n+/g, ' '));
        }
      }
    },
    [editor],
  );

  /*** 2초 동안 입력 없으면 변경 스크립트 자동 저장 ***/
  const saveAutomatically = useMemo(
    () =>
      debounce((uid) => {
        // 편집 화면의 스크립트가 변경되었고, 아직 변경 내용이 저장되지 않았으니 저장 실행.
        if (storage.get(`tasks.${uid}.isScriptChanged`)) {
          // console.log("스크립트 저장: 2초후 자동 저장: ", nowTime());
          dispatch(
            pushTaskScript({
              taskUid: uid,
              option: { callLocation: 'autoSave' },
            }),
          );
        }
      }, 2000),
    [dispatch],
  );

  /*** 스크립트 내용 변경 ***/
  const handleChange = useCallback(
    (newValue: Node[]) => {
      if (script !== newValue) {
        // 스토어 > 에디터 > 스크립트 값 변경
        dispatch(commitScript({ changedScript: newValue as ScriptParagraph[] }));

        // 로컬스토리지에 스크립트 저장
        storage.set(`tasks.${taskUid}.script`, newValue);

        // 스크립트 변경 확인을 위한 값.
        storage.set(`tasks.${taskUid}.isScriptChanged`, true);

        // 스크립트 변경 일시
        storage.set(`tasks.${taskUid}.scriptChangeDate`, moment().format('YYYY-MM-DD HH:mm:ss'));

        if (isFindReplaceOpen && isScriptEdited === false) {
          // 스크립트 내용이 바뀌었다는걸 알수 있게(찾기 바꾸기 관련)
          dispatch(editedScript(true));
        }
        saveAutomatically(taskUid);

        if (isSpellCheckCommitting) {
          // 맞춤법 반영중
          dispatch(finishCommitting());
        } else if (isSpellChecked) {
          // 맞춤법 검사 진행후 다시 편집이 이뤄짐
          dispatch(changeSpellChecked(false));
        }

        // 맞춤법 검사 실행후 - 제한 글자에 걸린 문단이 있는 경우
        if (isCheckingLength) {
          dispatch(changeSpellCheckingLimit(false));
        }
      }
    },
    [
      dispatch,
      script,
      saveAutomatically,
      taskUid,
      isFindReplaceOpen,
      isScriptEdited,
      isCheckingLength,
      isSpellChecked,
      isSpellCheckCommitting,
    ],
  );

  /*** 렌더 ***/
  const renderElement = useCallback(
    (renderProps: RenderElementProps) => {
      const speaker = (renderProps.element as ScriptParagraph).data.speaker || '화자없음';
      const data = renderProps.element.data as { type: string; end: number; start: number };
      let paragraphIndex = ReactEditor.findPath(editor, renderProps.element)[0];

      // 첫번째 문단의 화자 변경시: 스토어에 저장된 첫번째 문단의 화자명 변경
      if (paragraphIndex === 0 && speakerOfFirstParagraph && speaker !== speakerOfFirstParagraph) {
        dispatch(changeSpeakerOfFirstParagraph(speaker));
      }

      let lineBreak = false;
      if (data.type === 'lineBreak') {
        lineBreak = true;
      }

      let textGuide = '문단이 길어서 편집 시 성능 저하 문제가 발생할 수 있습니다.';
      let warningTooLong = false;
      // 단어가 1000개가 넘는다.
      if (renderProps.element.children.length > 1000) {
        warningTooLong = true;
      } else {
        // 맞춤법 검사에서 문단(문장)에서 글자수 제한이 있음
        if (isCheckingLength) {
          // 한 번 요청시 최대 입력 텍스트
          const { sentence } = config.limit.spellcheck;
          if (Node.string(renderProps.element).length > sentence) {
            warningTooLong = true;
            textGuide = `${toNum(sentence)}자 이상인 문장이 있습니다.`;
          }
        }
      }

      const lineBreakHotKey = (
        <>
          <kbd className="keystroke">shift</kbd> + <kbd className="keystroke">enter</kbd>
        </>
      );
      const warningTooLongText = (
        <>
          <span className="icon">경고!</span> {textGuide} {lineBreakHotKey}를 사용하여, 문단을
          분리해주세요.
        </>
      );

      const handleFindPath = () => {
        paragraphIndex = ReactEditor.findPath(editor, renderProps.element)[0];
      };

      return (
        <div
          className={classNames('paragraph', {
            'warning-too-long': warningTooLong,
            'line-break': lineBreak,
          })}
        >
          {warningTooLong && (
            <>
              <div className="notice-too-long-fix" contentEditable={false}>
                <span className="icon" />
                {textGuide} {lineBreakHotKey}를 사용하여, 문제가 발생하고 있는 문단(배경색이
                빨간색)을 분리해주세요.
              </div>
              <div className="notice-too-long-block top" contentEditable={false}>
                {warningTooLongText}
              </div>
              <div className="notice-too-long-block bottom" contentEditable={false}>
                {warningTooLongText}
              </div>
            </>
          )}
          <div className="paragraph-aside" contentEditable={false} data-index={paragraphIndex}>
            {lineBreak ? (
              <>&#65279;</>
            ) : (
              <SpeakerElement
                speaker={speaker}
                renderNode={renderProps.element}
                onFindPath={handleFindPath}
                readOnly={readOnly}
              />
            )}
          </div>
          <Paragraph {...renderProps} children={renderProps.children} />
        </div>
      );
    },
    [dispatch, editor, speakerOfFirstParagraph, readOnly, isCheckingLength],
  );

  const renderLeaf = useCallback((renderProps) => {
    return <Word {...renderProps} children={renderProps.children} />;
  }, []);

  /*** 시간점프시 해당 블럭으로 스크롤 이동 ***/
  useEffect(() => {
    if (playedSecondsForScroll !== 0 && !playedSecondsForScroll) {
      return;
    }
    let i;
    for (i = 0; i < editor.children.length; i++) {
      if ((editor.children as ScriptParagraph[])[i].data.end > playedSecondsForScroll) {
        break;
      }
    }
    editor.window.scrollToItem(Math.min(i, editor.children.length - 1));
  }, [editor, playedSecondsForScroll]);

  return (
    <Slate editor={editor} value={script} onChange={handleChange}>
      <HoveringToolbar />
      <Editable
        className="wrap-script"
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onDOMBeforeInput={handleBeforeInput}
        onKeyDown={handleKeyDown}
        onClick={handleClick}
        onPaste={handlePaste}
        readOnly={readOnly || isEventsFromOutside}
      />
    </Slate>
  );
};

/**
 * Paragraph element
 * @param element
 * @param attributes
 * @param children
 * @constructor
 */
const Paragraph = React.memo(
  ({
    element: { data: eleData, children: eleChildren },
    attributes,
    children,
  }: RenderElementProps) => {
    const startTime = useMemo(() => {
      return timeStamp((eleData as ScriptParagraph['data']).start);
    }, [eleData]);

    // 글자 갯수 노출 옵션이 활성화 상태라면.
    const textLength = useMemo(() => {
      let total = 0;
      eleChildren.forEach((c) => {
        const text = (_.get(c, 'text') as string | undefined) || '';
        total += text.length;
      });

      return toNum(total);
    }, [eleChildren]);

    return (
      <div className="paragraph-content" data-timestamp={startTime}>
        <em className="info text-length" data-timestamp={startTime} data-text-length={textLength} />
        <p className="wrap-words" {...attributes}>
          {children}
        </p>
      </div>
    );
  },
);

/**
 * Text element
 * @param leaf
 * @param children
 * @param attributes
 * @constructor
 */
const Word = React.memo(
  ({ leaf: { data: leafData, unrecognizable }, children, attributes }: RenderLeafProps) => {
    const playedSeconds = useSelector(
      (rootState: RootState) => rootState.workspace.player.playedSeconds,
    );

    const { dataEnd, dataStart, confidence } = useMemo(() => {
      return leafData as ScriptWord['data'];
    }, [leafData]);

    const confidenceValue = useMemo(() => {
      return confidence >= 0.5;
    }, [confidence]);

    const progress = useMemo(() => {
      if (Number(dataEnd) <= playedSeconds) {
        return 'past';
      } else if (Number(dataStart) <= playedSeconds && Number(dataEnd) > playedSeconds) {
        return 'current';
      }
      return 'before';
    }, [playedSeconds, dataStart, dataEnd]);

    return (
      <span
        className="word"
        {...attributes}
        data-progress={progress}
        data-unrecognizable={unrecognizable}
        data-confidence={confidenceValue}
        data-end={dataEnd}
        data-start={dataStart}
      >
        {children}
      </span>
    );
  },
);

/**
 * Speaker Select
 * @param props
 * @constructor
 */
const SpeakerSelect = React.memo(
  withStyles({
    root: {
      width: '100%',
    },
    select: {
      padding: '0 !important',
      borderRadius: '4px',
      fontSize: '16px',
      color: '#a4a4a4',
      lineHeight: '22px !important',
      letterSpacing: 'normal !important',
      whiteSpace: 'normal',
      '&:hover': {
        backgroundColor: '#5f5f5f',
      },
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      display: 'none',
    },
  })(React.memo(Select, (prev, next) => isEqual(prev, next))),
  (prev, next) => isEqual(prev, next),
);

/*** 앞/다음(이웃/근처) 문단의 화자 ***/
const neighboringParagraphSpeaker = (
  script: ScriptParagraph[],
  currentIndex: number,
  direction: 'prev' | 'next',
): string => {
  const index = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;
  const paragraphInfo = script[index] as ScriptParagraph;

  let speaker = paragraphInfo.data.speaker || '';
  // lineBreak(문단 분리가 아닌 줄 분리된 문단)의 화자 찾기.
  if (paragraphInfo.data.type === 'lineBreak') {
    // lineBreak(문단 분리가 아닌 줄 분리된 문단)가 마지막 문단이 아닌 경우에만 실행
    if (index !== script.length - 1) {
      speaker = neighboringParagraphSpeaker(script, index, direction);
    }
  }
  return speaker;
};

const SpeakerSelectBox = React.memo((props: any) => {
  const { speaker, renderNode, handleClose, open } = props;

  const taskSpeakers = useSelector(
    (rootState: RootState) => rootState.workspace.editSpeakers.speakers,
  );
  const script = useSelector((rootState: RootState) => rootState.workspace.editor.script);

  const [frontSpeaker, setFrontSpeaker] = useState<string | undefined>(undefined);
  const [nextSpeaker, setNextSpeaker] = useState<string | undefined>(undefined);

  const editor = useSlateStatic();
  const dispatch = useDispatch();

  const speakers = useMemo(() => {
    return taskSpeakers ? [...taskSpeakers] : [];
  }, [taskSpeakers]);

  const path = useMemo(() => {
    return ReactEditor.findPath(editor, renderNode);
  }, [editor, renderNode]);

  const paragraphIndex = useMemo(() => {
    return path[0];
  }, [path]);

  const speakersOption = useMemo(() => {
    return [...speakers, { name: '화자없음', feature: '' }];
  }, [speakers]);

  useEffect(() => {
    // 앞 문단 화자.(현재 문단이 첫번째가 아니라면, 앞 문단 정보를 찾는다.)
    if (paragraphIndex > 0) {
      setFrontSpeaker(
        neighboringParagraphSpeaker(script as ScriptParagraph[], paragraphIndex, 'prev'),
      );
    }

    // 다음 문단 화자.(현재 문단이 마지막이 아니라면, 다음 문단 정보를 찾는다.)
    if (paragraphIndex !== script.length - 1) {
      setNextSpeaker(
        neighboringParagraphSpeaker(script as ScriptParagraph[], paragraphIndex, 'next'),
      );
    }
  }, [script, paragraphIndex]);

  /* 화자 이름 출력 */
  const handleRenderValue = useCallback((value: any) => {
    return <button type="button" className="name-speaker" data-speaker={value} />;
  }, []);

  /* 화자 변경 */
  const handleSpeakerChange = useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      const changeName = event.target.value as string;
      const paragraphData = Array.isArray(script) && script[paragraphIndex].data;

      Transforms.setNodes(
        editor,
        {
          data: {
            ...paragraphData,
            speaker: changeName,
          },
        } as ScriptParagraph,
        { at: path },
      );

      // 첫번째 문단의 화자 변경시: 스토어에 저장된 첫번째 문단의 화자명 변경
      if (paragraphIndex === 0) {
        dispatch(changeSpeakerOfFirstParagraph(changeName));
      }
    },
    [dispatch, editor, paragraphIndex, script, path],
  );

  return (
    <SpeakerSelect
      id={`selectSpeaker-${paragraphIndex}`}
      value={speaker}
      disableUnderline={true}
      onChange={handleSpeakerChange}
      renderValue={handleRenderValue}
      open={open}
      onClose={handleClose}
    >
      {speakersOption.map((option: SpeakerInfo, index: number) => {
        const name = option.name;

        // 현재 문단의 화자명이 "화자없음" 인 경우에만 옵션에서 "화자없음" 제거.
        // 앞/다음 문단의 화자명('화자없음'예외)은 옵션으로 출력 하지 않음.
        if (
          (name !== '화자없음' && name === speaker) ||
          (frontSpeaker !== '화자없음' && name === frontSpeaker) ||
          (nextSpeaker !== '화자없음' && name === nextSpeaker)
        ) {
          return false;
        }
        return (
          <MenuItem
            key={index}
            value={name}
            disabled={name === speaker}
            style={{
              display: 'block',
              maxWidth: '200px',
              fontSize: '14px',
              whiteSpace: 'normal',
              wordBreak: 'keep-all',
              overflowWrap: 'break-word',
            }}
          >
            {name}
          </MenuItem>
        );
      })}
    </SpeakerSelect>
  );
});

/**
 * Speaker element
 * @param props
 * @constructor
 */
const SpeakerElement = React.memo(
  (props: { speaker: string; renderNode: any; readOnly?: boolean; onFindPath: () => void }) => {
    const { speaker, readOnly, onFindPath } = props;
    const [open, setOpen] = React.useState(false);

    const handleClose = useCallback(() => {
      setOpen(false);
    }, [setOpen]);

    const handleOpen = useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        // 이벤트 버블링을 막는다.(handleClick 실행을 막음)
        event.stopPropagation();
        onFindPath();
        setOpen(true);
      },
      [setOpen, onFindPath],
    );

    return (
      <>
        {open ? (
          <SpeakerSelectBox {...props} handleClose={handleClose} open={open} />
        ) : (
          <button
            type="button"
            className="name-speaker"
            data-speaker={speaker}
            disabled={readOnly}
            onClick={handleOpen}
          />
        )}
      </>
    );
  },
  (prev, next) => isEqual(prev, next),
);

export default React.memo(EditorBox);
