import TimeAgo from 'react-timeago';
import MenuItem from '@material-ui/core/MenuItem';
import React, { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { createScript } from './workspaceSlice';

import { NodeList, stringifySync } from 'subtitle';
import { saveAs } from 'file-saver';
import { pullTaskHistories, resetTaskScriptHistories } from './scriptHistorySlice';
import ModalAlert from './ModalAlert';
import ModalContentCopy from './ModalContentCopy';
import ModalHistory from './ModalHistory';
import MaterialMenu, { MenuProps } from '@material-ui/core/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { ScriptParagraph } from './types';
// @ts-ignore
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
// @ts-ignore
import koStrings from 'react-timeago/lib/language-strings/ko';
import moment from 'moment';
import { removeSpacesBeginningParagraphs } from './utility';
import { pushTaskScript } from './editorBoxSlice';
import { onCopyFileName } from './AsideTaskInfo';
import { MenuOutlined } from '@ant-design/icons';
import { push } from 'connected-react-router';
import { config } from '../../config';

const formatter = buildFormatter(koStrings);

type HeaderProps = {
  handleSave: () => void;
  readOnly?: boolean;
};

const Header = ({ handleSave, readOnly }: HeaderProps) => {
  const dispatch = useDispatch();
  const script = useSelector(
    (rootState: RootState) => rootState.workspace.editor.script,
  ) as ScriptParagraph[];

  const taskUid = useSelector((rootState: RootState) => rootState.workspace.repositories.task!.uid);
  const scriptFormat = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.task!.scriptFormat,
  );
  const editedAt = useSelector((rootState: RootState) => rootState.workspace.editor.editedAt);

  const isScriptUpdating = useSelector(
    (rootState: RootState) => rootState.workspace.editor.isScriptUpdating,
  );
  const isScriptSaved = useSelector(
    (rootState: RootState) => rootState.workspace.editor.isScriptSaved,
  );

  const playFileCreatedAt = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.playFile!.createdAt,
  );
  const playFileName = useSelector(
    (rootState: RootState) => rootState.workspace.repositories.playFile!.name,
  );

  const [taskTitleFileName, setTaskTitleFileName] = useState('');
  const [exportModalOpened, toggleExportModal] = useState(false);
  const [historyModalOpened, toggleHistoryModal] = useState(false);
  const [alertDelAllContentOpen, setAlertDelAllContentOpen] = useState(false);

  /*** 작업 제목 ***/
  const taskTitle = useMemo(() => {
    const fileAt = playFileCreatedAt
      ? `[${moment(playFileCreatedAt).format('YYYY-MM-DD HH:mm:ss')}]`
      : '';
    const fileName = playFileName ? playFileName : '-';
    setTaskTitleFileName(`${fileAt}${fileName}`);
    // 파일명 복사
    const handleCopy = () => {
      onCopyFileName(fileName);
    };

    return (
      <>
        <span className="noselect mr-1" onClick={handleCopy}>
          {fileAt}
        </span>
        <span onClick={handleCopy}>{fileName}</span>
      </>
    );
  }, [playFileCreatedAt, playFileName]);

  /*** "방금 후에 마지막 저장"문구 출력 오류 관련 ***/
  const editedAtUTC = useMemo(() => {
    if (editedAt) {
      return new Date(editedAt).toUTCString();
    }
    return editedAt;
  }, [editedAt]);

  /*** 내보내기 메뉴 ***/
  // TODO: 내보내기 레이어 팝업을 보여줄지 상태값인데 HTMLElement 을 할당하는 이유가 뭐였지?
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleExportMenuClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);

      // 편집 화면에서 변경한 스크립트가 DB에 저장되지 않았다면, 저장 실행.
      if (!isScriptSaved) {
        dispatch(pushTaskScript({ taskUid, option: { callLocation: 'exportContent' } })); // API 저장 요청: script
      }
    },
    [dispatch, taskUid, isScriptSaved],
  );

  const handleExportMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  /*** 내보내기 > 클립보드 modal ***/
  const handleExportModalClose = useCallback(() => {
    toggleExportModal(false);
  }, []);

  const handleExportModalOpen = useCallback(() => {
    setAnchorEl(null);
    toggleExportModal(true);
  }, []);

  /*** 내보내기 > srt 파일 저장 ***/
  const handleExportSrtFile = useCallback(() => {
    setAnchorEl(null);

    const scriptSpaceRemoved = removeSpacesBeginningParagraphs(script) as ScriptParagraph[];

    // srt 형식 데이터 만들기
    const meta: NodeList = [];
    scriptSpaceRemoved.forEach((paragraph) => {
      const captionText = paragraph.children.map((word) => word.text).join('');
      // paragraph.data.end * 1000
      // 시간 표시를 블록의 start, end 값을 사용할지 첫번째 자식의 start, 마지막 자식의 end 값을 사용할지 정하지 않음.
      const children = paragraph.children;
      const lastWord = children[children.length - 1];
      const lastWordEnd = lastWord.data.dataEnd;

      meta.push({
        type: 'cue',
        data: {
          // start: paragraph.data.start * 1000,
          // end: paragraph.data.end * 1000,
          start: children[0].data.dataStart * 1000,
          end: lastWordEnd * 1000,
          text: captionText,
        },
      });
    });

    const data = stringifySync(meta, { format: 'SRT' });

    // 파일로 생성후 다운로드
    const blob = new Blob([data], { type: 'text/plain;charset=utf-8' });
    saveAs(blob, taskTitleFileName + '.srt');
  }, [script, taskTitleFileName]);

  /*** 내용 전체 삭제 ***/
  const handleDelAllContent = useCallback(() => {
    setAlertDelAllContentOpen(true);
  }, []);

  const onAlertDelAllContentCancel = useCallback(() => {
    setAlertDelAllContentOpen(false);
  }, []);

  const onAlertDelAllContentConfirm = useCallback(() => {
    setAlertDelAllContentOpen(false);
    // 내용 전체 삭제 - 빈 block 으로 변경
    // console.log("스크립트 저장: 내용 전체 삭제: ", nowTime());
    dispatch(
      pushTaskScript({
        taskUid,
        value: [createScript],
        format: scriptFormat,
        option: {
          refresh: true,
          callLocation: 'deleteAllContents',
        },
      }),
    );
  }, [dispatch, taskUid, scriptFormat]);

  /*** 히스토리 모달 ***/
  const handleHistoryModalOpen = useCallback(() => {
    dispatch(pullTaskHistories(taskUid));
    toggleHistoryModal(true);
  }, [taskUid, dispatch]);

  const handleHistoryModalClose = useCallback(() => {
    dispatch(resetTaskScriptHistories());
    toggleHistoryModal(false);
  }, [dispatch]);

  /* 홈으로 이동 */
  const handleClickGoHome = useCallback(() => {
    dispatch(push(config.page.link.home));
  }, [dispatch]);

  return (
    <>
      <button type="button" className="btn-home mr-2 bg-transparent" onClick={handleClickGoHome} title="홈으로 이동">
        <MenuOutlined />
      </button>
      <header className="wrap-file-title">
        <div className="file-title edit-text">{taskTitle}</div>
      </header>
      <div className="wrap-aside">
        <em className="msg-work-save">
          {isScriptUpdating ? (
            '저장 중...'
          ) : !editedAtUTC ? (
            ''
          ) : (
            <>
              <TimeAgo date={editedAtUTC} formatter={formatter} />에 마지막 저장
            </>
          )}
        </em>

        <button
          type="button"
          className="button-work-save button-color1"
          onClick={handleSave}
          disabled={readOnly || isScriptSaved || isScriptUpdating}
        >
          저장
        </button>

        <button
          type="button"
          className="button-delete-all-content button-color1"
          onClick={handleDelAllContent}
          disabled={readOnly}
        >
          전체삭제
        </button>

        <button
          type="button"
          className="button-show-history button-color1"
          onClick={handleHistoryModalOpen}
        >
          히스토리
        </button>

        <button
          type="button"
          className="button-work-copy button-color1"
          aria-controls="exportMenu"
          aria-haspopup="true"
          onClick={handleExportMenuClick}
        >
          내보내기
        </button>

        {Boolean(anchorEl) && (
          <Menu
            id="exportMenu"
            anchorEl={anchorEl}
            keepMounted={true}
            open={Boolean(anchorEl)}
            elevation={0}
            onClose={handleExportMenuClose}
          >
            <MenuItem onClick={handleExportSrtFile}>SRT 파일</MenuItem>
            <MenuItem onClick={handleExportModalOpen}>클립보드 복사</MenuItem>
          </Menu>
        )}
      </div>

      {
        /* 내보내기>클립보드 모달 */
        exportModalOpened && (
          <ModalContentCopy isOpen={exportModalOpened} onClose={handleExportModalClose} />
        )
      }

      {
        /* 히스토리 모달 */
        historyModalOpened && (
          <ModalHistory
            isOpen={historyModalOpened}
            readOnly={readOnly}
            onClose={handleHistoryModalClose}
          />
        )
      }

      {
        /* 내용 전체 삭제 */
        alertDelAllContentOpen && (
          <ModalAlert
            title="알림"
            content={
              <>
                확인 버튼을 누르시면 편집된 본문의 내용이 모두 삭제됩니다.
                <br />
                본문 내용을 모두 삭제하시겠습니까?
              </>
            }
            isOpen={alertDelAllContentOpen}
            onClose={onAlertDelAllContentCancel}
            onConfirm={onAlertDelAllContentConfirm}
          />
        )
      }
    </>
  );
};
export default React.memo(Header);

const Menu = React.memo((props: PropsWithChildren<MenuProps>) => (
  <MaterialMenu {...props}> {props.children}</MaterialMenu>
));
