import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import { withStyles } from '@material-ui/core/styles';
import ModalAlert from './ModalAlert';
import { timeStamp, toDateTime } from '../../utils/format';
import classNames from 'classnames';
import { RootState } from '../../app/store';
import { pullTaskHistories, pullTaskHistory } from './scriptHistorySlice';
import _ from 'lodash';
import LoadingProgress from '../../components/LoadingProgress';

const DialogTitle = withStyles(() => ({
  root: {
    padding: '18px 0 0',
  },
}))(MuiDialogTitle);

const DialogContent = withStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    overflowY: 'hidden',
    paddingTop: '0',
    paddingBottom: '0',
  },
}))(MuiDialogContent);

const dateFormat = 'YYYY-MM-DD h:mm A';

const ModalHistory = ({
  isOpen: isModalHistoryOpen,
  onClose: onModalHistoryClose,
  readOnly,
}: {
  isOpen: boolean;
  onClose: () => void;
  readOnly?: boolean;
}) => {
  const dispatch = useDispatch();

  const taskScriptHistories = useSelector(
    (rootState: RootState) => rootState.workspace.scriptHistory.taskScriptHistories,
  );
  const selectedScript = useSelector(
    (rootState: RootState) => rootState.workspace.scriptHistory.selectedScript,
  );
  const restCount = useSelector(
    (rootState: RootState) => rootState.workspace.scriptHistory.restCount,
  );
  const isFetching = useSelector(
    (rootState: RootState) => rootState.workspace.scriptHistory.isFetching,
  );
  const lastId = useSelector((rootState: RootState) => rootState.workspace.scriptHistory.lastId);
  const taskUid = useSelector((rootState: RootState) => rootState.workspace.repositories.task!.uid);

  const [rollbackId, setRollbackId] = useState<undefined | number>(undefined);
  const [alertRollbackConfirm, setAlertRollbackConfirm] = useState(false);
  const [scrollTopValue, setScrollTopValue] = useState(0);

  const listBoxRef = useRef<HTMLDivElement>(null);
  // const listItemsRef = useRef<HTMLDListElement[] | []>([]);

  const [listBoxDOMRectHeight, setListBoxDOMRectHeight] = useState<undefined | number>(undefined);

  /* 되돌리기 확인창 '취소' */
  const onRollbackConfirmAlertClose = useCallback(() => {
    setAlertRollbackConfirm(false);
  }, []);

  /*** 모달 - 히스토리 : 히스토리 롤백 적용 ***/
  const onRollbackConfirmAlertConfirm = useCallback(() => {
    if (!rollbackId) {
      return;
    }
    setAlertRollbackConfirm(false);
    // 선택한 히스토리 스크립트 내용 불러오기
    dispatch(pullTaskHistory(taskUid, rollbackId, true));
    setRollbackId(undefined);
  }, [dispatch, taskUid, rollbackId]);

  /* '편집내용 보기' 클릭시 */
  const handleChangeScriptId = useCallback(
    (e) => {
      const targetHistoryId = Number(e.target.id);
      if (targetHistoryId) {
        // 선택한 히스토리 스크립트 내용 불러오기
        dispatch(pullTaskHistory(taskUid, targetHistoryId));
      }
    },
    [dispatch, taskUid],
  );

  /* '되돌리기' 클릭시 확인창 노출 */
  const handleRollbackConfirm = useCallback((e) => {
    const targetHistoryId = Number(e.target.id);
    if (targetHistoryId) {
      setRollbackId(targetHistoryId);
      setAlertRollbackConfirm(true);
    }
  }, []);

  /*** scroll Top 위치 값을 가져온다. ***/
  const onScroll = useCallback(
    _.throttle(() => {
      if (listBoxRef.current) {
        setScrollTopValue(listBoxRef.current.scrollTop || 0);

        if (!listBoxDOMRectHeight) {
          setListBoxDOMRectHeight(listBoxRef.current.getBoundingClientRect().height);
        }
      }
    }, 100),
    [listBoxDOMRectHeight],
  );

  /*** resize 이벤트: 윈도우 창 크기 변경시. ***/
  useEffect(() => {
    const debouncedHandleResize = _.debounce(() => {
      // window height 가 리사이징 되면 리스트 박스의 높이를 다시 구한다.
      setListBoxDOMRectHeight(listBoxRef.current!.getBoundingClientRect().height);
    }, 500);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  /*** scroll 이벤트: 불러올 목록이 남아 있는 경우에만 실행 ***/
  useEffect(() => {
    // 히스토리 목록 : 스크롤 이벤트
    if (restCount > 0) {
      listBoxRef.current && listBoxRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      listBoxRef.current && listBoxRef.current.removeEventListener('scroll', onScroll);
    };
  }, [restCount, onScroll]);

  /*** resetCount 와 스크롤 위치에 따라 남은 목록 호출 ***/
  useEffect(() => {
    // 남은 목록이 없다면, 아래 코드는 필요 없음.
    if (restCount === 0) {
      return;
    }

    // 리스트 호출 결과에 따라 바뀌는 엘리먼트의 정보값
    const listBox = listBoxRef.current;
    const listBoxHeight = listBoxDOMRectHeight;

    if (!isFetching && listBox && listBoxHeight) {
      const scrollHeight = listBox.scrollHeight;
      const scrollPositionY = scrollHeight - listBoxHeight - scrollTopValue;
      // 히스토리 목록 아이템의 높이값 90
      if (90 * 2 >= scrollPositionY) {
        // 남은 리스트 호출
        dispatch(pullTaskHistories(taskUid, lastId));
      }
    }
  }, [
    dispatch,
    taskUid,
    scrollTopValue,
    listBoxRef,
    listBoxDOMRectHeight,
    restCount,
    lastId,
    isFetching,
  ]);

  /*** 히스토리 목록이 있고, 첫 접근시 목록의 첫번째 목록의 아이디를 롤백 스크립트 아이디로 설정. ***/
  useEffect(() => {
    if (taskScriptHistories && taskScriptHistories.length > 0) {
      if (!selectedScript) {
        // 선택한 히스토리 스크립트 내용 불러오기
        dispatch(pullTaskHistory(taskUid, taskScriptHistories[0].id));
      }
    }
  }, [dispatch, taskScriptHistories, selectedScript, taskUid]);

  return (
    <>
      <Dialog
        open={isModalHistoryOpen}
        fullWidth={true}
        maxWidth="md"
        className={classNames('wrap-dialog dialog-history', {
          'isFetching': isFetching && !taskScriptHistories,
        })}
      >
        <DialogTitle className="wrap-dialog-title" disableTypography={true}>
          <h1 className="text-hidden">저장 기록</h1>
        </DialogTitle>

        <DialogContent className="dialog-content">
          <section className="sec-history-list">
            <header className="sec-header">
              <h2 className="sec-title">히스토리 목록</h2>
              {/*/ restCount: {restCount}*/}
            </header>
            <div className="wrap-content" ref={listBoxRef}>
              {isFetching && !taskScriptHistories && (
                <div className="wrap-loading">
                  <LoadingProgress />
                </div>
              )}
              {!taskScriptHistories || taskScriptHistories.length === 0 ? (
                <p className="no-data">저장된 히스토리가 없습니다.</p>
              ) : (
                taskScriptHistories.map((item) => {
                  const historyItemId = item.id;
                  const historyItemCreateAd = item.createdAt
                    ? toDateTime(item.createdAt, dateFormat)
                    : '';

                  return (
                    <dl
                      className="item"
                      key={'history' + historyItemId}
                      data-key={'history' + historyItemId}
                      /*ref={handleRefHistoriesListItem}*/
                    >
                      <dt className="text">
                        저장시간
                        {/*/ ID:{historyItemId} / index:{index + 1}*/}
                      </dt>
                      <dd className="text-date">{historyItemCreateAd}</dd>
                      <dd className="wrap-buttons">
                        <button
                          type="button"
                          className="button-script-show button-icon button-color7"
                          id={`${historyItemId}`}
                          disabled={(selectedScript && selectedScript.id) === historyItemId}
                          onClick={handleChangeScriptId}
                        >
                          편집내용 보기
                        </button>
                        <button
                          type="button"
                          className="button-rollback button-icon button-color6"
                          id={`${historyItemId}`}
                          disabled={readOnly}
                          onClick={handleRollbackConfirm}
                        >
                          되돌리기
                        </button>
                      </dd>
                    </dl>
                  );
                })
              )}
            </div>
          </section>

          <section className="sec-history-script">
            <header className="sec-header">
              <h2 className="sec-title">
                저장된 편집 내용
                {/*/ selectedScript.id:{selectedScript && selectedScript.id}*/}
              </h2>
              <dl className="save-date">
                <dt className="text-hidden">저장된 일시</dt>
                <dd className="text-date">
                  {selectedScript ? toDateTime(selectedScript.createdAt, dateFormat) : ''}
                </dd>
              </dl>
            </header>
            <div className="wrap-content wrap-script">
              {selectedScript
                ? selectedScript.script.value.map((paragraph, index) => {
                    let lineBreak = false;
                    if (paragraph.data.type === 'lineBreak') {
                      lineBreak = true;
                    }

                    return (
                      <div
                        className={classNames('paragraph', {
                          'line-break': lineBreak,
                        })}
                        key={'paragraph' + index}
                      >
                        <div className="paragraph-aside">
                          <div className="name-speaker">{paragraph.data.speaker || '화자없음'}</div>
                        </div>
                        <div
                          className="paragraph-content"
                          data-timestamp={timeStamp(paragraph.data.start)}
                        >
                          <div className="wrap-words">
                            {paragraph.children.map((word) => word.text)}
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ''}
            </div>
          </section>
        </DialogContent>

        <DialogActions className="dialog-actions">
          <button
            type="button"
            autoFocus={true}
            onClick={onModalHistoryClose}
            className="button-cancel button-color4"
          >
            닫기
          </button>
        </DialogActions>
      </Dialog>
      {alertRollbackConfirm && (
        <ModalAlert
          title="알림"
          content={
            <>
              에디터를 되돌릴 경우 현재 편집중인 에디터 내용이 변경됩니다.
              <br />
              해당 시점으로 편집 에디터를 되돌리시겠습니까?
            </>
          }
          isOpen={alertRollbackConfirm}
          onClose={onRollbackConfirmAlertClose}
          onConfirm={onRollbackConfirmAlertConfirm}
        />
      )}
    </>
  );
};

export default ModalHistory;
