import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toastr } from 'react-redux-toastr';
import { camelizeKeys, decamelizeKeys } from 'humps';
import _ from 'lodash';
import { config as configValue } from 'src/config';

enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}

export interface ResponseMessage {
  message: string;
  code?: number;
}

export type ResponseError = ResponseMessage & {
  field?: string;
  stack?: any;
};

export interface Error {
  code: number;
}

export interface Errors {
  errors: Error[];
}

export interface Meta {
  meta?: { [key: string]: any };
}

export type Data<D> = Meta & {
  data: D;
};

type RequestConfig = AxiosRequestConfig & {
  showMessage?: boolean | 'error';
};

type Status = 'success' | 'info' | 'warning' | 'error';

function showMessage(message: string, status: Status = 'info', code?: string | number) {
  toastr[status]('알림', message + (code ? ` (error:${code})` : ''));
}

export class ApiClient {
  private baseConfig: Partial<RequestConfig>;

  public constructor(config: object) {
    this.baseConfig = config;
  }

  public config(config: RequestConfig) {
    this.baseConfig = config;
  }

  public async get<D>(
    path: string,
    params: object | [] | FormData = {},
    config: RequestConfig = {},
  ) {
    const method = RequestMethod.GET;
    return await this.request<D>(path, method, params, config);
  }

  public async post<D>(
    path: string,
    data: object | [] | FormData = {},
    config: RequestConfig = {},
  ) {
    const method = RequestMethod.POST;
    return await this.request<D>(path, method, data, config);
  }

  public async patch<D>(
    path: string,
    data: object | [] | FormData = {},
    config: RequestConfig = {},
  ) {
    const method = RequestMethod.PATCH;
    return await this.request<D>(path, method, data, config);
  }

  public async put<D>(path: string, data: object | [] | FormData = {}, config: RequestConfig = {}) {
    const method = RequestMethod.PUT;
    return await this.request<D>(path, method, data, config);
  }

  public async delete<D>(path: string, config: RequestConfig = {}) {
    const method = RequestMethod.DELETE;
    return await this.request<D>(path, method, {}, config);
  }

  private async request<ReturnType>(
    path: string,
    method: RequestMethod,
    data: object | [] | FormData = {},
    options: RequestConfig,
  ): Promise<ReturnType> {
    delete options.showMessage;

    const config: RequestConfig = {
      url: path,
      method: method.toString() as RequestMethod,
      params: decamelizeKeys(method === RequestMethod.GET ? data : {}),
      data: decamelizeKeys(method !== RequestMethod.GET ? data : {}),
      ...options,
      headers: {
        ...options.headers,
      },
    };

    try {
      const response = await axios.request({
        ...this.baseConfig,
        baseURL: this.baseConfig.baseURL + `${path.indexOf('/nlp/') === -1 ? 'workroom' : ''}`,
        ...config,
      });
      const responseData = camelizeKeys(response.data as object, (key, convert) =>
        /[-]+/.test(key) ? key : convert(key),
      );
      // @ts-ignore
      return responseData as ResponseType;
    } catch (error) {
      const errorResponse = error.response as AxiosResponse<Error>;

      if (errorResponse && errorResponse.data) {
        const { data: errorData, status: errorStatus } = errorResponse;
        let message = _.get(errorData, 'message');
        const errorCode = _.get(errorData, 'code');
        let toastOption = {};

        switch (errorStatus) {
          case 500:
            toastOption = { timeOut: 0, removeOnHover: false };
            message = message || '처리중 오류가 발생하였습니다.\n 관리자에게 문의해주세요.';
            break;

          case 404:
            if (message === 'task를 찾을 수 없음') {
              message = '해당 작업건이 존재하지 않습니다.\n id를 다시한번 확인하세요.';
            }
            break;
        }

        if (!message) {
          message =
            '오류가 발생하였습니다.\n 관리자에게 문의해주세요.' +
            (message ? '\n[' + message + ']' : '');
        }

        // errorCode 가 없는 경우
        if (!errorCode) {
          toastr.error(
            '오류',
            message + (errorStatus ? '\n(statusCode: ' + errorStatus + ')' : ''),
            toastOption,
          );
        }

        throw errorResponse;
      } else {
        // show toastr
        showMessage('서버와의 통신을 실패했습니다.', 'error');
        throw new Error(`request failed. ${error}`);
      }
    }
  }
}

const apiClient = new ApiClient({});
apiClient.config({
  baseURL: configValue.env.baseApiURL,
});

export default apiClient;
