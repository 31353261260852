import { ReactPlayerProps } from 'react-player';
import { BaseEditor } from 'slate';
import { ReactEditor, WindowEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import { ISO8601Timestamp } from '../../models';
import { File, Order, SpeakerInfo, Task } from '../../models/taskTypes';

declare module 'slate' {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor & HistoryEditor & WindowEditor;
    Element: ScriptParagraph;
    Text: ScriptWord;
  }
}

export enum ScriptFormat {
  TPX = 'tpx',
  TXT = 'txt',
}

export interface TaskScriptHistory {
  id: number;
  script: {
    value: ScriptParagraph[];
    speakers: SpeakerInfo[];
  };
  scriptFormat: ScriptFormat;
  createdAt: ISO8601Timestamp;
}

// player 프로그래스에서 재생 값
export interface PlayerSecondsInProgress {
  loadedSeconds: number;
  playedSeconds: number;
  playedSecondsForScroll?: number;
}

export type Player = {
  duration: number;
  buffering: boolean;
  playerProps: ReactPlayerProps;
} & PlayerSecondsInProgress;

export interface Editor {
  script: ScriptParagraph[];
  editedAt: ISO8601Timestamp | null;
  isScriptUpdating: boolean; // DB에 스크립트 업데이트 중인지 상태 확인
  isScriptSaved: boolean; // 편집 화면에서 변경한 스크립트가 DB에 저장 되었는지 상태
  isEventsFromOutside: boolean; // 외부 이벤트로 에디터 값이 바뀌어야 하는 경우
  error: string | null;
}

export interface ScriptParagraph {
  object: string; // "block",
  type: string; // "paragraph",
  data: {
    speaker?: string;
    start: number;
    end: number;
    type?: string;
  };
  children: ScriptWord[];
}

export interface ScriptWord {
  text: string;
  data: {
    dataStart: number;
    dataEnd: number;
    confidence: number;
    recognizable?: boolean; // (인식 불가능) 초기값 true
  };
  unrecognizable?: boolean; // 식별 불가
}

export interface WorkspaceRepositoriesState {
  order?: Order;
  task?: Task;
  playFile?: File;
  isFetching: boolean;
  isValid: boolean;
  error: string | null;
}

export interface EditSpeakers {
  speakers: SpeakerInfo[];
  isSpeakersUpdating: boolean;
  error: string | null;
  errorSpeakersUpdate: string | null; // 화자 정보 업데이트 결과 - '오류'인 경우 변경하기 이전 값으로 초기화 하기 위해
  speakerOfFirstParagraph?: string; // 첫번째 문단의 화자
  processChangScript: 'ready' | 'changing' | 'changeSucceed' | 'changeFailed'; // 첫번째 문단의 화자
}

export type FindReplaceItem =
  Pick<FindReplaceResult, 'offset' | 'wordFind'>
  & { wordReplace: string; }

export type FindReplaceResult = {
  offset: [number, number];
  wordFind: string;
  context: {
    previous: string;
    next: string;
  };
};

export interface FindReplaceState {
  isOpen: boolean;
  isFinding: boolean;

  isReplacing: boolean;
  wordFind: string;
  wordReplace: string;
  recentWords: {
    wordFinds: string[];
    wordReplaces: string[];
  };
  results: FindReplaceResult[];
  noSearchResults: null | boolean; // 검색어 이력후 검색을 진행하기 전까지는 null.검색 결과 있다: true, 검색 결과 없다: false
  checkedIndexes: number[];
  seekToIndex?: number;
  isScriptEdited?: boolean; // 찾기 바꾸기 창에서 '검색'한 이후에 에디터 본문에서 직접 편집이 이뤄졌는지 확인
}

// 맞춤법 검사의 상태
export interface SpellCheckState {
  // 서버에서 응답받은 결과를 출력하기 편한 형태로 가공하여 저장
  data: SpellCheckData['data'];

  sentences: SpellCheckResultItem[][];

  // 교정 후보들, 에러 타입의 단어들(교정이 필요한 단어들)
  candidates: { [id: string]: SpellCheckResultItem };

  changes: { [id: string]: FindReplaceItem };

  isOpen: boolean;

  isFetching: boolean;

  isCheckingLength: boolean;

  error?: string;

  isSpellChecked: boolean; // 맞춤법 검사를 진행(반영,확인)후 편집하지 않았음

  isCommitting: boolean;

}

// 맞춤법 검사 오류 타입
export enum SpellCheckErrorType {
  NO_ERROR = 'no_error', // 옳은 어절
  SPELL = 'spell', // 철자 오류
  SPACE = 'space', // 띄어쓰기 오류
  SPACE_SPELL = 'space_spell', // 철자+띄어쓰기 오류
  DOUBT = 'doubt', // 오류 의심
}

export interface SpellCheckResultItem {
  input: string;
  output: string;
  etype: string;
  offset?: [number, number]; // 문장의 index, 단어 첫 글자의 index
  help?: [string];
}

export interface SpellCheckItem {
  sentence: string;
  result: SpellCheckResultItem[];
}

export interface SpellCheckData {
  data: SpellCheckItem[];
}

export interface SpellCheckParams {
  query: string;
  help?: string;
  onlySpace?: string;
  disableWeak?: string;
}

// 단축키
export interface ShortcutKeyObj {
  [p: string]: string[];
}

export enum ShortcutKeys {
  CTRL = 'Ctrl',
  SHIFT = 'Shift',
  CTRL_SHIFT = 'Shift,Ctrl',
  ARROW_LEFT = 'ArrowLeft',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
  ARROW_RIGHT = 'ArrowRight'
}

// 단축키로 등록된 이벤트 이름
export enum ShortcutEvents {
  SAVE = 'save', // 저장
  UNDO = 'undo', // 실행 취소
  REDO = 'redo', // 다시 실행
  PLAY_PAUSE = 'playPause', // 재생/일시 정지
  REWIND = 'rewind', // 초 뒤로 이동
  FAST_FORWARD = 'fastForward', // 초 앞으로 이동
  SPEED_UP = 'speedUp', // 재생 속도 빠르게
  SPEED_DOWN = 'speedDown', // 재생 속도 느리게
  SPEED_RESET = 'speedReset', // 재생 속도 초기화
  PLAY_CURRENT_LOCATION = 'playCurrentLocation', // 현재 위치 재생 (currentWordPlay)
  FIND_REPLACE = 'findReplace', // 찾기/바꾸기
  SPELL_CHECK = 'spellCheck', // 맞춤법 검사
  SHORTCUT_KEY = 'shortcutKey', // 단축키 안내
}